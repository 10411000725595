<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItems"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refBookingtimesListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <x-circle-icon
                :id="'popover-cancel'+data.item.id"
                size="2x"
                class="text-danger"
                v-if="data.item.cancelled">
            </x-circle-icon>

            <check-circle-icon
                :id="'popover-success'+data.item.id"
                v-else
                size="2x"
                class="text-success"
            ></check-circle-icon>


            <b-popover :target="'popover-cancel'+data.item.id" triggers="hover" placement="right" variant="danger" :title="$t('This event got cancelledbookings')">
              <b>   {{ $t("Reason") }}:</b>
              <div>{{ (data.item.cancelled_booking) ? data.item.cancelled_booking.message : "The Expert hasn’t given a Reason"  }}</div>
            </b-popover>

            <b-popover :target="'popover-success'+data.item.id" triggers="hover" placement="right" variant="success" :title="$t('This event was carried out')">
              <div> {{ $t("This event was carried out") }}</div>
            </b-popover>



          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay,
  BPopover
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from 'moment'
import {XCircleIcon, CheckCircleIcon} from 'vue-feather-icons'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import BCardActions from '@core/components/b-card-actions/BCardActions'
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    XCircleIcon,
    CheckCircleIcon,
    BPopover,
    vSelect,
    TableHeader,
    TableFooter,
    BCardActions,
  },
  directives: {
    // 'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {key: 'event.name', sortable: true, label: this.$i18n.t('Event Name')},
        {key: 'user.name', sortable: true, label: this.$i18n.t('Event Booked By')},
        {key: 'client_name', sortable: true, label: this.$i18n.t('Company Of Employee'), formatter: this.formatName,},
        {key: 'credits', sortable: true, label: this.$i18n.t('Credits Earned')},
        {
          key: 'start_date', sortable: true, label: this.$i18n.t('Start Date'),
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'end_date', sortable: true, label: this.$i18n.t('End Date'),
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons'}
      ],
      items: [],
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/experts/bookings/past`);
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/experts/bookings/past/${id}`,
      );
    },

    getWeekDayName(value, key, item) {
      let weekday = [
        {value: 1, text: "Sunday"},
        {value: 2, text: "Monday"},
        {value: 3, text: "Tuesday"},
        {value: 4, text: "Wednesday"},
        {value: 5, text: "Thursday"},
        {value: 6, text: "Friday"},
        {value: 7, text: "Saturday"},
      ];
      let weekDayName;
      weekday.forEach(function (item) {
        if (item.value == value) weekDayName = item.text;
      });
      return this.$t(weekDayName)
    },
    formatName(value, key, item) {
      if (item.user.client.firstname == null && item.user.client.name == null) return item.user.client.firm;

      if (!item.user.client.firstname) return item.user.client.name;

      return item.user.client.firstname + " " + item.user.client.name;

    }


  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
